package com.bibek.webproject.models

import com.bibek.webproject.utils.Constants.LOREM_IPSUM_LONG

val Blogs = listOf(
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Extention Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Normal Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Test Title Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Power Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "India Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG), Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG), Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG), Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG), Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG), Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG), Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG), Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG), Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),
    Blog(title = "Inline Function", desc = LOREM_IPSUM_LONG),

    )

data class Blog(
    val title: String,
    val desc: String
)